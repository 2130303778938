<template>
	<div class="ql-snow">
		<div class="ql-editor" v-html="content"></div>
	</div>
</template>

<script>
import "vue2-editor/dist/vue2-editor.css"
import "quill/dist/quill.core.css"
import "quill/dist/quill.bubble.css"
import "quill/dist/quill.snow.css"

export default {
	name: 'RictTextDisplay',
	props: {
		content: {
			type: String,
			default: ''
		}
	}
}
</script>

<style lang="scss" scoped>
.ql-editor {
	min-height: unset;
}
</style>
